.services-view {
  height: 92vh;
}

@media (max-width: 450px) {
  .services-view {
    height: 700px;
  }
}
@media (min-width: 451px) and (max-width: 740px) {
  .services-view {
    height: 500px;
  }
}
@media (min-width: 800px) and (max-width: 850px) {
  .services-view {
    height: 550px;
  }
}

.streak.streak-md {
  height: auto;
}

.btn.pink-gradient,
.btn.pink-gradient:active,
.btn.pink-gradient:focus {
  background: linear-gradient(40deg, #ff5858, #ee4392);
}

.icon-area .circle-icon i {
  box-shadow: 0 8px 19px rgba(91, 154, 251, 0.41);
  width: 100px;
  height: 100px;
  line-height: 1.4;
  padding: 1.8rem;
  font-size: 2rem;
  border-radius: 50%;
  background: #fff;
}
.icon-area .circle-icon i:hover {
  background: #ebeef9;
}

.servicesPage {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 0.95rem;
}

.orange-text {
  color: #fb5364 !important;
}

html {
  scroll-behavior: smooth;
}